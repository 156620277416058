.button {
  display: inline-block;
  font-size: 0.9rem;
  line-height: 0.9rem;
  background: rgb(255, 255, 255);
  cursor: pointer;
  padding: 1rem 4.3rem;
  font-family: "Arial";
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.2rem;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
}

.button:hover {
  opacity: 0.6;
}

.button-block {
  display: block;
}

.button-primary {
  background: #057cb6;
  color: #ffffff;
  border: 0px !important;
}

.button-dafault {
  background: rgba(255, 255, 255, 1);
  color: #c1c1c1;
}

.button-link {
  background: #ffffff;
  color: #057cb6;
  box-shadow: none;
  padding: 0.7rem;
  display: inline;
  text-decoration: none;
}

.button-small {
  padding: 0.4rem 1rem;
}
