.loginFormWpr {
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  background: rgb(248, 248, 248);
}

.loginFormCnt {
  width: 55rem;
  height: 40rem;
  margin: 0 auto;
  background: white;
}

.loginImageContainer,
.loginFormContainer {
  width: 50%;
  display: flex;
  justify-content: stretch;
  padding: 2rem 1rem;
  margin: 0 auto;
}

.loginFormContainer form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.loginFormContainer form > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.loginFormContainer form > div.forgetpassword {
  align-items: flex-end;
  padding-top: 0.5rem;
}
.loginFormContainer form > div.loginbutton {
  margin-top: auto;
}
.loginImageContainer {
  align-items: center;
  justify-content: center;
}

.loginFormWpr .labelTitle {
  font-family: "Arial";
  font-weight: bold;
  font-size: 1.08rem;
  color: #5a5a5a;
  line-height: 1.25em;
  display: block;
}

.loginFormWpr .InputText {
  display: block;
}
.loginFormWpr .button-login {
  width: 100%;
  margin: 0;
}

.loginFormWpr .button-link {
  margin: 0;
}

.padTp1_5 {
  padding-top: 1.5rem;
}

.padTp0_5 {
  padding-top: 0.5rem !important;
}

.padBt0_5{
  padding-bottom: 0.5rem !important;
}

.lgRow .backButton {
  padding-left: 0;
}
